<template>
  <div class="chat">
    <!-- 加载中 -->
    <div class="cover-wrap" v-show="loading_switch"></div>
    <div class="loading-box" v-show="loading_switch">
      <van-loading vertical class="loading" color="#fff">等待中，请稍后...</van-loading>
    </div>

    <div class="content-wrap">
      <!-- <div class="content-banner" @click="handleGoQ1()">
        <img src="../../assets/image/chat_img1.png" alt="chat_img1" />
      </div> -->

      <div class="content-form">
        <div class="phone_box">
          <div class="phone_lable">联系电话</div>
          <div class="phone_tip">请填写联系电话，方便必要时联系您</div>
          <div class="phone_input">
            <input type="text" placeholder="填写您的联系方式" v-model="phone" :class="{ act: ishas1 }" />
          </div>
        </div>
      </div>

    </div>

    <div class="footer-wrap">
      <div class="chat_btn" @click="handleSubmitForm()">办理退款</div>
      <a href="tel:4007752588">
        <div class="phone_btn">
          <div class="phone_icon">
            <img src="../../assets/image/xx_icon.png" alt="" />
          </div>
          <div class="phone_font">电话极速退款</div>
        </div>
      </a>
    </div>

    <!-- <div class="b-back" @click="handleBack()">
      <img src="../../assets/image/new_back_btn.png" alt="" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'ChatView',
  data() {
    return {
      phone: '',
      ishas1: false,
      loading_switch: false,
      timer:null,
    };
  },
  watch: {
    phone() {
      if (this.phone) {
        this.ishas1 = false;
      }
    },
  },
  destroyed(){
    clearTimeout(this.timer)
  },
  methods: {
    //为啥被扣款
    handleGoQ1() {
      this.$router.push('./question1');
    },
    //返回
    handleBack() {
      this.$router.go(-1);
    },
    //在线联系
    async handleSubmitForm() {
      let that = this;
      
      if (!this.phone) {
        window.scrollTo(0, 0);
        this.ishas1 = true;
        this.$toast('请填写联系方式');
        return;
      }

      if (this.phone.length != 11 || !(/^1[34578]\d{9}$/.test(this.phone))) {
        window.scrollTo(0, 0);
        this.ishas1 = true;
        this.$toast('联系方式不合法');
        return;
      }

      this.loading_switch = true;

      let order_no = "";

      let cond = `is_pass=1&order_no=${order_no}&phone=${this.phone}`;
      let res = await this.$axios
        .post('https://transfer.aitici.com/help_server', cond, {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        })
        .catch(function (error) {
          console.log(error);
          that.loading_switch = false;
          that.$toast('网络出错,请重试');
          return
        });

        if (res.data) {
          let info = { uid: '', data: [], gid: '482707004' };
          info.uid = this.phone;
          let cond1 = `url=http://test776.aitici.com/index.php/index/Sevenfish/sevenfishList&is_post=0`;
          let result = await this.$axios
            .post(`https://complaint-service.aitici.com/index/index/zhuanfa`, cond1, {
              headers: {
                'content-type': 'application/x-www-form-urlencoded',
              },
            })
            .catch(function (error) {
              console.log(error);
            });
          // console.log('客户分组', result.data.data);
          if (result.data) {
            if (result.data.data.code == 200) {
              result.data.data.data.forEach((item, index) => {
                if (item.type == 3) {
                  info.gid = item.h5_id;
                }
              });
            }
          }

          info.data = [
            { key: 'real_name', value:'支付宝' },
            {
              key: 'avatar',
              label: '头像',
              value: 'https://newstore.vynior.com/zfb.png',
              href: 'https://newstore.vynior.com/zfb.png',
              isCustomField: true,
            },
            
            {
              key: '联系电话',
              label: '联系电话',
              value: this.phone,
              isCustomField: true,
            }
          ];
          localStorage.setItem('phone', this.phone);
          await this.fishkefu.Config(info);
          let qysdk = await this.fishkefu.openSdk();
          if (qysdk == 'sdk尚未加载成功，请稍后再试') {
              this.timer = null
              this.timer = setTimeout(async ()=>{ 
                  await this.fishkefu.Config(info);
                  let qysdk1 = await this.fishkefu.openSdk();
                  this.loading_switch = false;
                  if(qysdk1 == 'sdk尚未加载成功，请稍后再试'){
                      alert("sdk尚未加载成功，请稍后再试")
                  }
              
              },3000)
          } else {
            this.loading_switch = false;
          }
        }

      
    },
  },
};
</script>

<style lang="less">
@import url('./chat.less');
</style>
