import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import axios from 'axios'
Vue.prototype.$axios = axios

import fishKefu from './utils/fishkefu' //七鱼客服
Vue.prototype.fishkefu = fishKefu

//适配
import 'amfe-flexible'

// 按需引入element组件
import './plugins/vant.js'

//解决跳转路由之后不会回到顶部问题
router.afterEach(() => {
  window.scrollTo(0,0); //切换路由之后滚动条始终在最顶部
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
