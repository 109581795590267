<template>
  <div id="app">
    <router-view/> 
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      
    };
  },
  mounted() {
    this.fishkefu.init();
  },
  
};
</script>

<style lang="less">
*{
  margin: 0;
  padding: 0;
  list-style: none;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
div{
  box-sizing: border-box;
}

.van-toast {
  padding: 0 32px!important;
  font-size: 28px !important;
  line-height: 60px !important;
}
.van-image{
    width: 150px!important;
    height: 150px!important;
}
</style>
